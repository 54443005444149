import React from 'react'
import rodeo from '../multimedia/crecimiento_rodeo.jpg';

function CrecimientoRodeo() {
    return (
        <div id="crecimientorodeo">
            <div className='seccion'>
                <h2>CRECIMIENTO DEL RODEO LECHERO</h2>
                <img src={rodeo} className='diapo' alt='crecimiento_rodeo'></img>
                <div className='descripcion'>
                    <h3>Descripción:</h3>
                    <div className='texto'>
                        <p>Aplicación web desarrollada en el marco del convenio público-privado de cooperación científico-técnica entre
                            la <a href='https://unl.edu.ar/' target="_blank" rel="noopener noreferrer">Universidad Nacional del Litoral</a>, a través de su <a href='https://fca.unl.edu.ar/'
                                target="_blank" rel="noopener noreferrer">Facultad de Ciencias Agrarias</a> y la empresa <a a href='https://saltoagro.ar/'
                                    target="_blank" rel="noopener noreferrer">Salto Agro S.S.</a></p>
                        <p>Permite calcular el crecimiento genuino del rodeo lechero (sin compra de vacas ni vaquillonas).</p>
                        <p>Simula su evolución a cinco años. Permite evaluar cómo impactan determinados cambios en los parámetros del rodeo sobre su evolución. </p>
                        <p>Compara escenarios alternativos con la situación original. Emite reportes para descargar en formato PDF.</p>
                    </div>
                </div>
                <div className='generalidades'>
                    <ul>
                        <li><b>Requerimientos:</b> navegador web</li>
                        <li><b>Sistema operativo:</b> cualquiera</li>
                        <li><b>Dispositivos:</b> cualquiera</li>
                        <li><b>Tipo de licencia:</b> gratuita</li>
                        <li><b>Video tutorial:</b> disponible</li>
                        <li><b>Guía del usuario:</b> no disponible</li>
                        <li><b>Ayudas rápidas:</b> disponibles</li>
                        <li><b>Capacitación inicial:</b> recomendada</li>
                        <li><b>Complejidad:</b> baja/intermedia</li>
                    </ul>
                </div>
                <br></br>
                <div>
                    <h3>Presentación de la aplicación (incluye tutorial):</h3>
                </div>
                <div className='texto'>
                    <p>Videograbación del evento virtual de presentación de la aplicación realizado el 20 de septiembre de 2024.</p>
                </div>
                <div className="videos">
                    <iframe className='video' width="336" height="189" src="https://www.youtube.com/embed/riHQWVnCHfQ?si=gqpcUYiJHD5zUuaO" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <br></br>
                <a href='https://milecheria.ar/crecimientorodeolechero/' target="_blank" rel="noopener noreferrer">Ir a la aplicación</a>
                <br></br>
            </div>
        </div>
    )
}

export default CrecimientoRodeo